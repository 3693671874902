import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import React, { useEffect, useMemo, useState } from 'react';

import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import SEO from '../components/seo';
import TrailTitle from '../components/TrailTitle';
import qs from 'qs';
import styled from '@emotion/styled';

const BgRow = styled(Row)`
  background-image   : url('${(props) => props.background}');
  background-repeat  : no-repeat;
  background-position: top right;
`;

const CareersPage = ({ location, data }) => {
  const query                         = location.search ? qs.parse(location.search.substring(1)) : {};
  const [searchValue, setSearchValue] = useState(query.search || '');
  const careers                       = useMemo(() => data.careers.edges, [data]);

  const search = (event) => {
    event.preventDefault();

    setSearchValue(event.currentTarget.elements.search.value.trim());
  };

  return (
    <>
      <SEO title='Info Karir' />
      <Img fluid={data.cover.childImageSharp.fluid} />
      <BgRow className='pt-5' background={data.bg.childImageSharp.fixed.src}>
        <Col
          xs        = {'12'}
          md        = {'5'}
          className = 'd-flex flex-column align-items-center align-content-center'>
          <TrailTitle open className='pb-2 mb-5'>
            <div>Info Karir</div>
          </TrailTitle>
        </Col>
        <Col
          xs        = {'12'}
          md        = {{ span: '8', offset: 2 }}
          className = 'd-flex flex-column align-items-center align-content-center'>
          <Form onSubmit={search} css={{ width: '100%' }}>
            <InputGroup className='mb-3'>
              <FormControl
                placeholder  = 'Cari Posisi'
                aria-label   = 'Cari'
                name         = 'search'
                defaultValue = {searchValue}
              />
              <InputGroup.Append>
                <Button type='submit' variant='primary'>
                  Cari
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
          <h2
            className = 'mb-3 pb-2'
            css       = {{ borderBottom: '1px solid var(--primary)', width: '100%' }}>
            Job List
          </h2>
          <Row
            className = 'align-items-start align-content-center'
            css       = {{ width: '100%' }}>
            {careers
              .filter(({ node: { title: { value } } }) =>
                searchValue
                  ? value
                      .toLowerCase()
                      .match(new RegExp(searchValue.toLowerCase()))
                  :  true
              )
              .map(({ node: { title, content } }, idx) => (
                <Col md={'6'} key={idx} className='mb-2'>
                  <Card>
                    <Card.Body>
                      <Card.Title className='bg-dark text-white p-3 mb-2'>
                        {title.value}
                      </Card.Title>
                      <ReactMarkdown
                        source     = {content.value}
                        escapeHtml = {false}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            {careers.length === 0 && (
              <Col>
                <div className='d-flex justify-center align-items-center align-content-center p-5 bg-secondary'>
                  Tidak ada lowongan terbaru
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </BgRow>
    </>
  );
};

export const query = graphql`
  {
    careers: allCockpitCareers(filter: { display: { value: { eq: true } } }) {
      edges {
        node {
          cockpitId
          cockpitCreated
          title {
            value
          }
          content {
            value
          }
        }
      }
    }
    bg: file(name: { eq: "bg-ring" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover: file(name: { eq: "bg-common" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: WEBP) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default CareersPage;
